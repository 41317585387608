.custom-modal {
    .modal-dialog {
        .modal-content {
            border-radius: 1rem;
            border: 0;
        }
        .modal-title {
            font-size: 1rem;
        }
        .close {
            font-weight: 300;
            font-size: 2rem;
            opacity: 1;
            padding-top: 0.4rem;
            padding-bottom: 0.5rem;
        }
    }

    .modal-body {
        // max-height: 75vh;
        overflow: auto;
    }
    .modal-footer {
        border-top: 0;
    }
    &.w650 {
        .modal-dialog {
            max-width: 650px;
        }
    }
}

.table-style {
    thead {
        background-color: $col12;
        th {
            font-weight: 600;
            border: 0;
        }
    }
    tbody {
        tr {
            td {
                color: $col3;
            }
            &:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }
}

.chip {
    background-color: $col8;
    padding: 10px 60px 10px 20px;
    border-radius: 100px;
    display: inline-block;
    position: relative;
    font-size: 12px;
    font-weight: 500;
    color: $col11;
    margin-right: 10px;
    margin-bottom: 10px;
    span {
        position: absolute;
        right: 14px;
        top: -5px;
        font-size: 2rem;
        font-weight: 400;
        cursor: pointer;
        color: $col9;
    }
}

//-----------Loader
.loaderWrap {
    background: rgba(145, 145, 145, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
}
.loader {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    list-style-type: none;
}
.loader .center {
    width: 40px;
    height: 40px;
    background: $col1;
    border-radius: 50%;
    animation: center 3.2s ease-in-out infinite;
}
.loader .item {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 0;
    right: 0;
    margin: auto;
    background: $col1;
    border-radius: 50%;
}

.item-1 {
    animation: anim-1 3.2s ease-in-out infinite 0.2s;
    animation-fill-mode: backwards;
}

@keyframes anim-1 {
    0%,
    60%,
    100% {
        transform: rotate(45deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(45deg) translateX(0) scale(1.5);
    }
}
.item-2 {
    animation: anim-2 3.2s ease-in-out infinite 0.4s;
    animation-fill-mode: backwards;
}

@keyframes anim-2 {
    0%,
    60%,
    100% {
        transform: rotate(90deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(90deg) translateX(0) scale(1.5);
    }
}
.item-3 {
    animation: anim-3 3.2s ease-in-out infinite 0.6s;
    animation-fill-mode: backwards;
}

@keyframes anim-3 {
    0%,
    60%,
    100% {
        transform: rotate(135deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(135deg) translateX(0) scale(1.5);
    }
}
.item-4 {
    animation: anim-4 3.2s ease-in-out infinite 0.8s;
    animation-fill-mode: backwards;
}

@keyframes anim-4 {
    0%,
    60%,
    100% {
        transform: rotate(180deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(180deg) translateX(0) scale(1.5);
    }
}
.item-5 {
    animation: anim-5 3.2s ease-in-out infinite 1s;
    animation-fill-mode: backwards;
}

@keyframes anim-5 {
    0%,
    60%,
    100% {
        transform: rotate(225deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(225deg) translateX(0) scale(1.5);
    }
}
.item-6 {
    animation: anim-6 3.2s ease-in-out infinite 1.2s;
    animation-fill-mode: backwards;
}

@keyframes anim-6 {
    0%,
    60%,
    100% {
        transform: rotate(270deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(270deg) translateX(0) scale(1.5);
    }
}
.item-7 {
    animation: anim-7 3.2s ease-in-out infinite 1.4s;
    animation-fill-mode: backwards;
}

@keyframes anim-7 {
    0%,
    60%,
    100% {
        transform: rotate(315deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(315deg) translateX(0) scale(1.5);
    }
}
.item-8 {
    animation: anim-8 3.2s ease-in-out infinite 1.6s;
    animation-fill-mode: backwards;
}

.selfChildModelFade {
    // display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1051;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}

@keyframes anim-8 {
    0%,
    60%,
    100% {
        transform: rotate(360deg) translateX(40px) scale(1);
    }
    10%,
    50% {
        transform: rotate(360deg) translateX(0) scale(1.5);
    }
}
@keyframes center {
    0%,
    10%,
    90%,
    100% {
        transform: scale(0.7);
    }
    45%,
    55% {
        transform: scale(1);
    }
}
//---------------
.selfFade {
    // display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1051;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}

// Dharam start code css
.content-page {
    .Transmaincont {
        padding: 0;
    }
}
.mt20 {
    margin-top: 20px !important;
}
.overFlows {
    overflow: hidden;
}

// Dharam end code css

@media (max-width: 991px) {
    .left-side-menu {
        left: -100%;
    }
    .collapseDrawer {
        .left-side-menu {
            left: 0;
        }
    }
    .content-page {
        margin-left: 0;
    }
    .navbar-custom {
        left: 0;
    }
}

@media (max-width: 767px) {
    .left-side-menu {
        width: 100%;
    }
    body .self-text-danger {
        position: relative;
    }
}
//filter css
.commonTaFilter {
    .secondFilterSection {
        .mlMinus20 {
            margin-left: -20px;
        }
    }
}
//before page title heading
.marginTop15Desktop {
    margin-top: 15px;
}
// for sweetaler modal
.swal-modal {
    .swal-text {
        text-align: center;
    }
    .swal-footer {
        text-align: center;
        margin-bottom: 13px;
        .swal-button-container {
            .swal-button {
                font-weight: 500;
                padding: 0.575rem 1rem;
                font-size: 0.875rem;
                &.swal-button--cancel {
                    color: #fff;
                    background-color: #a5a5a5;
                    border-color: #a5a5a5;
                }
                &.swal-button--danger {
                    color: #fff;
                    background-color: #f0565a;
                    border-color: #f0565a;
                }
            }
        }
    }
}

// toast css
.Toastify {
    .Toastify__toast-container {
        .Toastify__toast {
            .Toastify__close-button {
                align-self: center;
            }
        }
    }
}

// menu
// .navbar-custom{
//   .logoutlist.show {
//     transform: translate(-68%, 72px) !important;
//   }
// }

// inventory table
.inventoryTable {
    .MuiTableCell-stickyHeader,
    .MuiTableCell-alignRight {
        text-align: center;
    }
}

// toast css end
.addSubMaterialButtonDiv {
    margin-bottom: 3rem;
    margin-top: -1.25rem;
}

// common css for all the mat inputs
.MuiInputBase-fullWidth,
.form-control {
    min-width: 10rem;
}

.swal-text {
    font-family: "roboto" !important;
    font-size: 0.875rem !important;
    position: relative;
    float: none;
    line-height: normal;
    vertical-align: top;
    text-align: left;
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    font-weight: 400;
    color: #000;
    max-width: calc(100% - 20px);
    overflow-wrap: break-word;
    box-sizing: border-box;
}

.swal-btn-hover:hover {
    background-color: #f10 !important;
}
