@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &:not(:disabled):not(.disabled):active,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
    box-shadow: none !important;
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    background-image: none;
    border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus,
    &:disabled {
      background-color: darken($background, 35%);
      border-color: darken($border, 35%);
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}

//circle
@mixin circle ($width, $height) {
width: $width;
height:$height;
border-radius: 50%;
}

//text ellipsis

@mixin ellipsis ($width) {
  width: $width;
  text-overflow: ellipsis;
  overflow: hidden;
  }