body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-size: 0.875rem;
}

input, button, select, optgroup, textarea{
  font-size: 0.875rem;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Roboto';
}

.left-banner{
  background-image: url('../../src/assets/img/loginbg.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  .logo-set{
    top: 20px;
    left: 20px;
  }
  .bannerText{
    margin-top: 27%;
  }
}
.otp-box{
  .input-style{
    width: 50px;
    height: 70px;
    input{
      height: 100%;
      font-weight: bold;
      font-size: 1.2rem;
      color: $col1;
      text-align: center;
    }
  }
}

#app-container{
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.left-side-menu{
  width: 255px;
    background: $col1;
    bottom: 0;
    padding: 20px 0;
    position: fixed;
    transition: all .1s ease-out;
    top: 0;
    padding-top: calc(70px + 20px);
    z-index: 999;
    left: 0;
    overflow-y: scroll;
}

.left-side-menu::-webkit-scrollbar {
  display: none;
}

.left-side-menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.content-page{
  margin-left: 255px;
    overflow: hidden;
    padding: 70px 15px 0px 15px;
    min-height: 100vh;
    background-color: $col10;
}




.navbar-custom {
  background-color: $col8;
  // box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
  padding: 0 20px 0 20px;
  position: fixed;
  left: 256px;
  right: 0;
  top: 0;
  height: 70px;
  transition: all .1s ease-out;
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-box {
  height: 70px;
  width: 255px;
  transition: all .1s ease-out;
  background-color: $col1;
  position: fixed;
  top: 0;
  z-index: 1;
}

.collapseDrawer{
  .left-side-menu{
    left: -100%;
  }
  .content-page{
    margin-left: 0;
  }
  .navbar-custom{
    left: 0;
  }
}

.side-menu{
.dropdown-toggle{
  position: relative;
  display: flex;
  align-items: center;
  word-break: break-word;
  white-space: normal;
  width: 100%;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 0;
  text-align: left;
  line-height: 15px;
  height:3rem;
  font-size:1rem;
  padding: .6rem 1.5rem;
  &:after{
      border: solid $col8;
      border-width: 0 2px 2px 0;
      vertical-align: middle;
      position: absolute;
      right: 15px;
      top: 31%;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      vertical-align: middle;
  }
}
// &.show {
//   .dropdown-toggle{
//     background-color: transparent;
//   border: 0;

//   }
// }

.dropdown-menu{
  position: relative !important;
  transform: none !important;
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  border:0;
  font-size: 1rem;
  
  >a{
      color: $col8;
      padding: 0.55rem 1.5rem;
      padding-left: 60px;
      white-space: normal;
      display: block;
    text-decoration: none;
      &:hover, &.active{
        background-color: $col13;
        color: $col8;
      }
  }
}
}

.single-menu{
  position: relative;
  display: flex;
  align-items: center;
  word-break: break-word;
  white-space: normal;
  width: 100%; 
  color: $col8;
  height: 3rem;
  line-height: 15px;
  // font-size: 0.8125rem;
  font-size: 1rem;
  padding: .6rem 1.5rem;
  transition: color 0.15s ease-in-out;
  &:hover{
    text-decoration: none;
    color: $col8;
  }
}

.nav-ico{
  background-color: $col11;
  border-radius: 5px;
  padding: 5px;
  width: 25px;
  margin-right: 10px;
}

.single-menu, .side-menu .dropdown-toggle{
  margin-bottom: 1px;
  &:hover, &.active{
    background-color: $col11 !important;
    color: $col8 !important;
    // .nav-ico{
    //   background-color: $col1;
    // }
  }
}
.side-menu.show .dropdown-toggle{
  background-color: $col11 !important;
  color: $col8 !important;
  .nav-ico{
    background-color: $col1;
  }
}
.l_o{
 .nav-ico{
  background-color: $col2;
 }
  &:hover{
    .nav-ico{
      background-color: $col2;
     }
  }
  }