
// bootstrap lg
@media(max-width:991px){
    // sidebar 
    .single-menu{
        line-height: inherit;
        font-size: 1rem;
        padding: 1.8rem 1.5rem;
        
    }
    .side-menu{
        .dropdown-toggle{
            padding: 1.8rem 1.5rem;
            &+.dropdown-menu{
                padding:0;
                a{
                    padding: 1rem 1.5rem;
                }
            }
        }
    }
    .left-side-menu{
        padding-top: 62px;
    }
    .logo-box{
        position: relative;
    }
     //header
     .navbar-custom{
        padding: 0px 10px;   
        height: 62px;
        .dropdown{
            .btn{
                padding: 0.575rem 0.875rem;
            }
        }
        .dropdown-toggle::after{
            display: none;
        }
    }
    //pagination
    .mobilePagginationWrapper {  
        flex-direction: column;
        padding-top:10px;    
    }
    
}
// bootstrap md 
@media(max-width:767px){
    .content-page{
        padding: 60px 0 0 0;
        .marginTop15Desktop{
            margin-top: 0px;
        }
       
        //common filter
        .commonTableFilter{
            .secondFilterSection{ 
                .btn{
                    width: 100%;
                }
                .mlMinus20{
                    margin-left: 0px;
                }
            }
           .thirdFilterSection{
               .btn{
                   width: 100%;
               }
           } 
        }
        //pagination
        // .mobilePagginationWrapper {  
        //     flex-direction: column;
        //     padding-top:10px;    
        // }
    }
    .Gst-per-qnt {
        margin-top: 10px;
        tr {
            td {
                padding-left: 0;
            }
        }
    }
    //common page heading
    .pageHeading {
        font-size: 1.2rem;
        padding:0.5rem 0;
    }
    //breadcrumb
    ul.breadcrumb {
        margin-bottom: 0px;
        li {      
            font-size: 0.875rem;
        }
    }

    // inward css by mahendrdra
    //for add inward details page tabs
    .MuiAppBar-root{
        .MuiTabs-flexContainer{
            .MuiTab-root{
                flex-basis: 50%;
                text-align: center;
            }
        }
        
    }
    
    .addSubMaterialButtonDiv{
        margin-bottom: 4rem;
        margin-top: 2rem;
      }
    // material management
    .mw-150{
        max-width: 150px;
    }
   
}
// bootstrap sm
@media(max-width:576px){   
    .content-page {
        .Transmaincont {    
            .innerhead {
                h6.font-weight-bold {
                    text-align: center;
                }
                .selfmargin {
                    display: block; 
                    width: 100%; 
                }
            }
        }   
      }
      .flex-mb-column {    
          flex-direction: column;
          .pull-right {
              width: 100%;
          }
          button.btn-danger {
              display: block; 
              width: 100%; 
          }
      }
      .md-2 {
          margin-bottom: 10px !important;
      }
      .settings.dropdown-menu-right{
        left: -80px !important;
        right: auto !important;
      }
}


//ipad landcsape
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

}
//iphonex landscape
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

