@font-face {
    font-family: 'roboto';
    src: local('roboto'), url(./assets/fonts/roboto/Roboto-Regular.ttf) format('truetype');
  }
body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'scss/vars';
@import 'scss/mixins';
@import'scss/button';
@import 'scss/inputs';
@import'scss/text';
@import 'scss/layout';
@import 'scss/common';
@import 'scss/transporter.scss';
@import 'scss/custom.scss';
@import 'scss/breadcrumb';
@import 'scss/pagination';
@import 'scss/responsive';
@import 'scss/dashboard.scss';


