.brdr-radius{
    border-radius:0.313rem;
}
 
.h2, h2 {
    font-size: 1.5rem;
}

.searchinput input{
    padding-left:3rem
}

.self-text-danger{
    position:relative;
    margin-bottom: 0;
}

#button-1 .knobs:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -1px;
    width: 19px;
    height: 15px;
    border:1px solid #666;
    color: #fff;
    padding: 9px 4px;
    background-color: #5C5C5C;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}


.modal-footer > * {
    margin: .5rem;
}

@media only screen and (min-width: 600px) {

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-bordered thead td, .table-bordered thead th {
        // min-width: 90px;
        border-bottom-width: 2px;
    }
  

   
  }

  @media only screen and (max-width: 992px) {
    .res-margin{
        margin-left:1.25rem;
        margin-top:1rem!important;
    }
    .res-process{
        margin-top: 70px!important;
    }
    .res-total{
        margin-top: -100px;
    }
  }
//   @media only screen and (min-width: 1365px) {

//     .table-responsive {
//         display: block;
//         width: 100%;
//         overflow-x: hidden!important;
//         -webkit-overflow-scrolling: touch;
//     }
  
//   }

  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding-top: .75rem !important;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem;
}

.progressabr{
    // display: block;
    height:0.4rem;
    // color:#1C2D61;
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #1C2D61;
    transition: width .6s ease;
}
.progress{
    height:0.3rem
}


.form-control:disabled, .form-control[readonly] {
    background-color:#f8f9fa00;
    opacity: 1;
}

.floatleft{
    float:left;
}

.MuiPaper-elevation4 {
    box-shadow: none!important;
    /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
}

.MuiAppBar-colorPrimary {
   
    background-color: fff0!important;
}
.MuiAppBar-colorPrimary {
    /* color: #fff; */
    color: #000!important;
    background-color: #ffffff00!important;
}

.PrivateTabIndicator-colorSecondary-3 {
    background-color: #1C2D61!important;
}

.nav-ico {
    background-color: #395CD6;
    border-radius: 6px;
    padding: 0px; 
    width: 25px;
    margin-right: 10px;
}

// .MuiMenu-list {
//     background: #fff;
//     border-radius: 5px;
//     box-shadow: 0px 3px 7px 8px rgba(0, 0, 0, 0.026);
//     border:none;
//     max-height: 70vh;
//     overflow-y: auto;
// }
// .MuiMenu-list:after {
// 	bottom: 100%;
// 	left: 85%;
// 	border: solid transparent;
// 	content: " ";
// 	height: 5;
// 	width: 0;
// 	position: absolute;
// 	pointer-events: none;
// 	border-bottom-color: #fff;
// 	border-width: 8px;
// 	margin-left: -8px;
// }

.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
}

.bell,
.logoutlist,
.settings{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 7px 8px rgba(0, 0, 0, 0.026);
    border:none;
    max-height: 70vh;
    overflow-y: auto;
}



.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    line-height: 2;
    color: #696969;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}



.white{
    background-color: #fff;
}

.hiddden-pading{
    padding:0px!important;
}


// table {
//     border-spacing: 0;
//     border: 1px solid black;
// thead{
//     tr {
//       :first-child {
//         th {
//           border-bottom: 0;
//           width:10px!important;
//           background-color: #395CD6;
//         }
//       }
//     }}
// }
.MuiTableCell-stickyHeader {
    top: 0;
    left: 0;
    z-index: 99!important;
    position: sticky;
    background-color: #E7E5E5!important;
    font-weight: 600!important;
}
// .MuiTableCell-root { 
//     // padding:5px!important;
//     padding: .45rem .35rem!important;
//     border: 0.5px solid #E7E5E5;
//     font-size: 0.75rem!important;
// }

// .MuiTableCell-root .form-control{font-size: .75rem!important;

// }



// .MuiTableCell-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
//     padding-left: 6px;
//     font-size: .75rem;
// }

// .MuiTableCell-root .MuiFormLabel-root {
//     font-size: .75rem!important;

// }

// .MuiTableCell-root .MuiSvgIcon-root {
//     fill: currentColor;
//     width: .75em;
//     height: .75em;
//     display: inline-block;
//     font-size: 1rem;
//     // transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//     // flex-shrink: 0;
//     // user-select: none;
// }

// .MuiTableCell-root {
//     display: table-cell;
//     padding: 16px;
    
//     text-align: left;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     line-height: 1.43;
//     border-bottom: 1px solid rgba(224, 224, 224, 1);
//     letter-spacing: 0.01071em;
//     vertical-align: inherit;
// }
// .MuiSvgIcon-fontSizeSmall {
//     font-size: .725rem!important;
// }
.Text-align {
    text-align: center !important;
    margin-top: 160px;
    font-size: 1.2rem;
}
.tooltip-inner {
    max-width: 500px !important;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem;
    font-size: .7rem;}

.Text-align1 {
    font-size: 1rem;
    // width: 100%;
    // position:absolute;
    margin-top:5px;
    // text-align: center !important;

    font-size: 1rem;
    /* width: 281vw; */
    position: absolute;
    /* top: 50%; */
    left: 50%;
    transform: translate(50%, -50%);
}


.Gst-per-qnt tr {
    height: 35px;
}


.Gst-per-qnt tr td{
    padding-left: 20px;
}

.options-disabled{
    &:disabled{
        color: rgb(163, 162, 162);
    }
}

.MuiAccordionSummary-content.Mui-expanded {
    // margin: 0px!important;
    // min-height: 48px;
    // padding-top: 12px;
    
    margin: 0px 0px!important;
    min-height: 25px;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    // display: flex;
    // padding: 0px 16px;
    // min-height: 48px;
    // transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px!important;
}

// for white list
.ListWhite{
    background: #FFFFFF;
    box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.05);
}
.bg-white{
    background: #FFFFFF;
}

.gfg {
    border-collapse:separate;
    border-spacing:0 15px;
    tbody{
        tr{
            td{
                color: #000;
            }
        }
    }
    td, th{
        border-top: 0;
    }
}

.MuiInputBase-fullWidth, .form-control {
    min-width: 3rem; 
}

.cstmstamp{
    background-color: #ffffff;
    border: 1.5px dashed #1C2D61;
    border-radius: 14px;
    width: 350px;

    padding:10px;
    margin: 0px;
    height: 200px;
}

.cstmfontsize{
    font-size: 1rem;
    font-weight: bold;
    padding:0;
}