ul.breadcrumb {
 
    padding: 0px ;
    list-style: none;
    background-color: #fff0;
  }
  ul.breadcrumb li {
    display: inline;
    font-size: 1rem;
    font-weight: 500;  }
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
  }
  ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
  }
  ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
  }