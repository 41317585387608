.btn{
  font-weight: 500;
  // text-transform: capitalize;
  padding: .575rem 1rem;
  font-size: 0.875rem;
}
.btn-danger{
  @include button-variant(#fff, $col2, $col2);
}

.btn-secondary{
  @include button-variant(#fff, $col6, $col6);
}

.btn-primary{
  @include button-variant(#fff, $col11, $col11);
}

.icon-Button{
  border: 0;
  background-color: transparent;
  outline: 0;
  line-height: 1;
  &:focus{
    outline: 0;
  }
}

label.myLabel input[type="file"] {
  position:absolute;
  top: -1000px;
  display: none;
}

// .myLabel {
//   background: $col7;
// border: 2px dashed $col13;
// box-sizing: border-box;
// border-radius: 6px;
// text-align: center;
// width: 100%;
// display: flex;
// align-items: center;
// justify-content: center;
// flex-direction: column;
// padding: 10px;
// height: 120px;
// overflow: hidden;
// 

.myLabel {
background: #F8F8F8;
/* border: 2px dashed #273B81; */
box-sizing: border-box;
border-radius: 6px;
/* text-align: center; */
width: 100%;
/* display: flex; */
/* align-items: center; */
/* justify-content: center; */
flex-direction: column;
padding: 7px;
/* height: 120px; */
overflow: hidden;
white-space: nowrap;
.docIco{
    width: 25px;
   margin: 0px 10px  0 0;
   }
}

.myLabel :invalid + span {
  color: $col6;
}
.myLabel :valid + span {
  color:$col6;
}

//switch button
.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.switch{
    position: relative;
    width: 35px;
    height: 15px;   
    
}

.switch.r, .layer{
    border-radius: 100px;
}


.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    background-color: $col12;
    transition: 0.3s ease all;
    z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before
{
    content: '';
    position: absolute;
    top: -2px;
    left: 0px;
    width: 15px;
    height: 15px;
    color: #fff;
    padding: 9px 4px;
    background-color: $col3;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before{
    content: '';
    left: 19px;
    background-color: $col1;
}

#button-1 .checkbox:checked ~ .layer
{
    background-color: $col12;
}

#button-1 .knobs, #button-1 .knobs:before, #button-1 .layer
{
    transition: 0.3s ease all;
}

.emoji-btn{
  border-radius:5px;
  padding: 5px 10px 5px 10px;
  background:white;
  border:0.5px solid #a1a7bd;
  margin-right:13px;
  margin-bottom: 3px;
}

.emoji-btn:hover{
  &:not([disabled]){
  background-color: #e6e4e4;
  }
}

.emoji-btn:disabled{
  color:black
}
// .emoji-btn:focus{
//   border:2px solid gray;
//   background-color: rgb(226, 224, 224);
// }

// :target{
//   border:2px solid gray;
//   background-color: rgb(226, 224, 224);
// }

.processSwitchBtn {
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  height: 24px;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 56px;
  
  
  &__label {
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  &__input {
    opacity: 0;
    
    &:checked {
      ~ .processSwitchBtn__text {
        background: #395CD6;
        
        &:before {
          opacity: 0;
          transform: translateX(200%);
        }
        
        &:after {
          opacity: 1;
          transform: translateX(0);
        }
      }
      
      ~ .processSwitchBtn__handle {
        transform: translateX(92px);
      }
    }
  }
  
  &__text {
    background-color: #1C2D61;
    border-radius: 50px;
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    height: 30px;
    margin: 0;
    position: absolute;
    top:7px;
    width: 122px;
     
    &:before,
    &:after {
      color: #fff;
      font-size: 14px;
      // font-weight: 700;
      line-height: 30px;
      position: absolute;
      transition: all .2s ease-in-out;
      transition-property: transform;
    }
    
    &:before {
      content: attr(data-off);
      right: 8px;
      transform: translateX(0);
    }
    
    &:after {
      color: white;
      content: attr(data-on);
      left: 9px;
      opacity: 0;
      transform: translateX(-300%);
    }
  }
  
  &__handle {
    background-color: white;
    border-radius: 18px;
    display: block;
    height: 24px;
    margin: 3px;
    //margin-left:0px;
    padding: 0;
    position: absolute;
    top: 7px;
    transition: all .2s ease-in-out;
    transition-property: transform;
    width: 24px;
  }
  
  &--large {
    height: 30px;
    width: 70px;
     
    .processSwitchBtn__input {
      &:checked {
        ~ .processSwitchBtn__handle {
          transform: translateX(40px);
        }
      }
    }
    
    .processSwitchBtn__text {
      height: 30px;
      width: 70px;
      
      &:before,
      &:after {
        font-size: 14px;
        line-height: 30px;
        position: absolute;
      }
      
      &:before {
        right: 10px;
      }

      &:after {
        left: 12px;
      }
    }
    
    .processSwitchBtn__handle {
      height: 26px;
      width: 116px;
    }
  }      
  
  &--no-text {
    .processSwitchBtn__text {
      &:before,
      &:after {
        display: none;
      }
    }
  }
  
  &--success {
    .processSwitchBtn__input {
      &:checked {
        ~ .processSwitchBtn__text {
          background: #5ED37A;
        }
      }
    }
  }
}