.input-style{
  .form-control{
    background-color: $col8;
    border: 0;
    border-radius: 6px;
    height: calc(1.5em + .75rem + 8px);
    padding: .375rem 1rem;
    border: 1px solid $col12;
    font-size: 0.847rem;
  }
  select{
    appearance: none;
    -webkit-appearance: none !important; 
    -moz-appearance: none !important;
    background-image: url('../assets/img/down-arrow.svg'); 
    background-repeat: no-repeat;
    background-position: 96% 50%;
    background-size: 15px;
  }
}

$color1: #f4f4f4;
$color2: #3197EE;
.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: $col8;
        border-radius: 100%;
        border: 2px solid darken($col9, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        // top: -0.2em;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $col11;
          box-shadow: inset 0 0 0 3px $col8;
          border-color: $col11;
        }
      }
    }

    &:checked {
      &:disabled {
        + .radio-label {
          &:before {
            //box-shadow: inset 0 0 0 4px $color1;
            border-color: darken($col8, 25%);
            background-color: darken($col8, 25%);
            // background: darken($col8, 25%);
          }
        }
      }
    }
  


    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $col11;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          //box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($col8, 25%);
          // background: darken($col8, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}


.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-bottom: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background: $col8;
    border-radius: 4px;
    border:1px solid $col6;
   
    transition: all .3s ease;
  }

  // Box hover
  // &:hover + label:before {
  //   background: #f35429;
  // }
  
  // Box focus
  // &:focus + label:before {
  //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  // }

  // Box checked
  &:checked + label:before {
    background: $col1;
    border-color:$col1;
  
  }
  &:checked {
    &:disabled {
      + label:before {
        background: $col1;
        border-color:$col6;
        opacity: .4;
      cursor: not-allowed;
      }
    }
    
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: not-allowed;
    opacity: .7;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #fff;
    border:1px solid $col6;
    opacity: .4;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

*::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $col6 !important;
}
*::-moz-placeholder { /* Firefox 19+ */
  color: $col6 !important;
}
*:-ms-input-placeholder { /* IE 10+ */
  color: $col6 !important;
}
*:-moz-placeholder { /* Firefox 18- */
  color: $col6 !important;
}
*::placeholder {
  color: $col6 !important;
}

// Chrome, Safari, Edge, Opera 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox 
input[type=number] {
  -moz-appearance: textfield;
}

.searchBox{
  input{
    background-color: $col8;
    border: 0;
    outline: 0;
    padding: 8px 8px 8px 35px;
    border-radius: 6px;
  }
  img{
    left: 10px;
    top: 8px;
  }
}
.form-control { 
    border: 1px solid #dee2e6;
}