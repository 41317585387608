
h2 {
    color: #1C2D61;
    padding: 0rem 0 1rem 0;
}

// .bg-white {
//     background-color: #fff0 !important;
// }

.Transmaincont {
    padding:1rem;
    background-color: #fff;
    margin-bottom: 1rem;

    .selfmargin {
        margin-bottom: 1rem;
    }
}

.TransportDetail .form-control {
    border: none;
}

.TransportDetail {
    // padding:1rem;

}

hr{
    margin-top:0;
}




.innerhead,
.innercont {
    padding: 1rem 1rem 0 1rem;

    // border-bottom:1px solid #E7E5E5;
}

.secondColor {
    background-color: #395CD6;
    border: 1px solid #395CD6;
}

.secondColor:hover {
    background-color: #1C2D61;
    border: 1px solid #1C2D61;
}

.brdrnone input{border:none }