$col1:#1C2D61;
$col2:#F0565A;
$col3:#5C5C5C;
$col4:#9C9C9C;
$col5:#4FC6E0;
$col6:#A5A5A5;
$col7:#F8F8F8;
$col8:#ffffff;
$col9:#000000;
$col10:#f8f8f8;
$col11:#395CD6;
$col12:#E7E5E5;
$col13:#273B81;
