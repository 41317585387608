



.tablepagination{
  display: flex;
  justify-content: flex-end;
}

.tablepagination button{
  letter-spacing: 0.5px;
  font-family: monospace;
  border: none;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 0 15px 0px 15px;
}
.tablepagination span {
  font-size: 0.75rem;
  color: #5C5C5C;
  // letter-spacing: 1px;
}
.tablepagination select{
  margin:0px 15px 0px 5px ;
  border-radius: 4px;
  font-size: .75rem;
}

