// font-weight

.fw500{
  font-weight: 500;
  }

// font-size

  $font-size:(
  fs10:0.625rem,
  fs12:0.75rem,
  fs13:0.8125rem,
  fs14:0.875rem,
  fs15:0.9375rem
);

@each $name, $size in $font-size {
  .#{$name} {
    font-size: $size;
  }
}

// font-color

.textBlue{
  color: $col1;
}
.textGray{
  color: $col3;
}
.textGray1{
  color: $col6;
}
.textPurpel{
  color: $col11;
}

.bgBlue{
  background-color: $col1;
}

.bgGray{
  background-color: $col7;
}