
.btns button {margin:5px;
margin-right:0;
}
.btns{
    margin-left:auto;
}

.fontsize75{
    font-size: .75rem;
}

.mytbl thead tr th{    background-color: #E7E5E5 !important;
padding:1rem;
}

.mytbl tbody tr td{   
    padding:1rem;
    }


    @media only screen and (max-width: 600px) {
        .cstmwidth{
            overflow-y: scroll;

        }
        .restbl{ overflow-x: auto;}   
      }
  table, th, td{
      border:1px solid #e7e5e5;
  }

  .self-text, .self-clr-bold{
      color: #1c2d61;
      font-weight: 600;
  }

//   border: 1px solid #dee2e6;

.my-padding{
    // width: 108%!important;
    padding-left: 5px!important;
    padding-right: 0px!important;
}